import './App.css';
import {Test} from "./Test";
import {Admin} from "./Admin";
import AdditionTask from "./Tasks/AdditionTask";
import SubtractionTask from "./Tasks/SubtractionTask";
import MultiplyTask from "./Tasks/MultiplyTask";
import DivisionTask from "./Tasks/DivisionTask";

const AvailableTasks = {
  '+': AdditionTask,
  '-': SubtractionTask,
  '*': MultiplyTask,
  '/': DivisionTask,
};

function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const reset = urlParams.get('reset');
  if (reset !== null) {
    window.localStorage.clear();
    window.location.href = '/';
  }
  const admin = urlParams.get('admin');

  const Component = admin === null ? Test : Admin;

  const types = urlParams.get('types') ?? '+-*/';
  const tasksPool = [];
  for (let i = 0; i < types.length; ++i) {
    tasksPool.push(AvailableTasks[types[i]]);
  }

  return (
    <div className="App">
      <Component pool={tasksPool}/>
    </div>
  );
}

export default App;
