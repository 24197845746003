import React from 'react';
import {History} from "./History";

export class Admin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            history: new History(window.localStorage),
        };
    }

    render() {
        return (
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <ul className="list-group">
                            {
                                this.state.history.entries.map((entry, i) =>
                                    <li className="list-group-item" key={i}>
                                        { ['CompareTask', 'FractionCompareTask'].includes(entry.taskClass) && (
                                            <>
                                                <div className="d-inline-block">{ entry.task._condition.condition }&nbsp;&nbsp;&nbsp;</div>
                                                <div className="pl-5 d-inline-block">
                                                    { entry.taskClass === 'FractionCompareTask' && '1/' }{ entry.task._first }
                                                    &nbsp;или&nbsp;
                                                    { entry.taskClass === 'FractionCompareTask' && '1/' }{ entry.task._second }
                                                </div>
                                            </>
                                        ) }
                                        { ['AdditionTask', 'SubtractionTask'].includes(entry.taskClass) && (
                                            <>
                                                <div className="pl-5 d-inline-block">
                                                    { entry.task._first } { entry.taskClass === 'AdditionTask' ? '+' : '-' } { entry.task._second } = { entry.answer }
                                                </div>
                                            </>
                                        ) }
                                        { ['MultiplyTask'].includes(entry.taskClass) && (
                                            <>
                                                <div className="pl-5 d-inline-block">
                                                    { entry.task._first } * { entry.task._second } = { entry.answer }
                                                </div>
                                            </>
                                        ) }
                                        { ['DivisionTask'].includes(entry.taskClass) && (
                                            <>
                                                <div className="pl-5 d-inline-block">
                                                    { entry.task._first } : { entry.task._second } = { entry.answer }
                                                </div>
                                            </>
                                        ) }
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
