import React from "react";
import {AdditionTaskComponent} from "./AdditionTaskComponent";

export class DivisionTaskComponent extends AdditionTaskComponent {
    render() {
        const inputStyle = {
            width: '80px',
            textAlign: 'center',
        };

        const classes = ['form-control'];
        let correctAnswer = null;

        if (this.props.submitted) {
            if (this.getTask().checkAnswer(this.state.answer).correct) {
                classes.push('is-valid');
            } else {
                classes.push('is-invalid');
                correctAnswer = this.getTask().correctAnswer;
            }
        }

        return (
            <div className="row fs-4 row-cols-2 justify-content-center">
                <div className="col-auto">
                    {this.getTask().first} : {this.getTask().second} =
                </div>
                <div className="col-auto">
                    <div className="input-group">
                        <input
                            className={ classes.join(' ') }
                            type="text"
                            style={inputStyle}
                            value={this.state.answer}
                            onChange={this.handleInput}
                            readOnly={this.props.submitted}
                            ref={(input) => { this.input = input; }}
                        />
                        { correctAnswer && <span className="input-group-text">{ correctAnswer }</span> }
                    </div>
                </div>
            </div>
        );
    }
}
