import {AbstractTask} from "../AbstractTask";
import Random from "../Random";
import {DivisionTaskComponent} from "./TasksComponents/DivisionTaskComponent";

export default class DivisionTask extends AbstractTask {
    constructor() {
        super();

        const second = Random(1, 30);
        this._first = second * Random(1, 30);
        this._second = second;
    }

    getComponent() {
        return DivisionTaskComponent;
    }

    checkAnswer(answer) {
        return {
            correct: +(answer === this.correctAnswer),
            wrong: +(answer !== this.correctAnswer),
            total: 1,
        };
    }

    get className() {
        return 'DivisionTask';
    }

    get correctAnswer() {
        return this.first / this.second;
    }

    /**
     * @returns {Number}
     */
    get first() {
        return this._first;
    }

    /**
     * @returns {Number}
     */
    get second() {
        return this._second;
    }
}
