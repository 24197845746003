export class Stats {
    total;
    correct;
    wrong;

    constructor(total, correct, wrong) {
        this.total = total;
        this.correct = correct;
        this.wrong = wrong;
    }
}

export class HistoryEntry {
    task;
    taskClass;
    answer;

    constructor({ task, taskClass, answer }) {
        this.task = task;
        this.taskClass = taskClass;
        this.answer = answer;
    }
}

export class History {
    storage;
    entries = [];
    stats;

    constructor(storage) {
        this.storage = storage;
        const entries = this.storage.getItem('history');
        const stats = this.storage.getItem('stats');
        if (!stats) {
            this.stats = new Stats(0, 0, 0);
        } else {
            this.stats = JSON.parse(stats);
        }

        if (!entries) {
            this.entries = [];
        } else {
            try {
                this.entries = JSON.parse(entries);
            } catch (e) {
                this.entries = [];
                this.storage.setItem('history', JSON.stringify(this.entries));
            }

        }
    }

    addEntry(entry) {
        this.entries.push(entry);

        this.storage.setItem('history', JSON.stringify(this.entries));
    }

    clear() {
        this.entries = [];

        this.storage.setItem('history', JSON.stringify(this.entries));
    }

    addWrong() {
        ++this.stats.wrong;
        ++this.stats.total;
        this.storage.setItem('stats', JSON.stringify(this.stats));
    }

    addCorrect() {
        ++this.stats.correct;
        ++this.stats.total;
        this.storage.setItem('stats', JSON.stringify(this.stats));
    }
}
