import {AbstractTask} from "../AbstractTask";
import Random from "../Random";
import {SubtractionTaskComponent} from "./TasksComponents/SubtractionTaskComponent";

export default class SubtractionTask extends AbstractTask {
    constructor() {
        super();

        this._first = Random(2, 999);
        this._second = Random(1, this._first);
    }

    getComponent() {
        return SubtractionTaskComponent;
    }

    checkAnswer(answer) {
        return {
            correct: +(answer === this.correctAnswer),
            wrong: +(answer !== this.correctAnswer),
            total: 1,
        };
    }

    get className() {
        return 'SubtractionTask';
    }

    get correctAnswer() {
        return this.first - this.second;
    }

    /**
     * @returns {Number}
     */
    get first() {
        return this._first;
    }

    /**
     * @returns {Number}
     */
    get second() {
        return this._second;
    }
}
