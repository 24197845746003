import React from 'react';
import Random from "./Random";
import {History, HistoryEntry} from "./History";

export class Test extends React.Component {
    constructor(props) {
        super(props);
        this.history = new History(window.localStorage);
        this.state = {
            ...this.history.stats,
            task: null,
            answer: null,
            submitted: false,
            key: 0,
            taskClass: null,
        };

        this.newTask = this.newTask.bind(this);
        this.onTaskAnswerUpdate = this.onTaskAnswerUpdate.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.newTask();
    }

    submit(event) {
        const score = this.getTask().checkAnswer(this.state.answer);

        const newState = {
            ...this.state,
            submitted: true,
            total: this.state.total + score.total,
            correct: this.state.correct + score.correct,
            wrong: this.state.wrong + score.wrong,
        };

        this.setState(newState);

        if (score.wrong) {
            this.history.addWrong();
            this.history.addEntry(new HistoryEntry(newState));
        } else {
            this.history.addCorrect();
        }

        event.preventDefault();
    }

    newTask() {
        const availableTasks = this.props.pool;
        const task = new availableTasks[Random(0, availableTasks.length - 1)]();

        this.setState({
            ...this.state,
            submitted: false,
            task: task,
            taskClass: task.className,
            key: this.state.key + 1,
            answer: null,
        });
    }

    /**
     * @returns {null|AbstractTask}
     */
    getTask() {
        return this.state.task;
    }

    onTaskAnswerUpdate(answer) {
        this.setState({
            ...this.state,
            answer,
        });
    }

    render() {
        const Task = this.getTask() ? this.getTask().getComponent() : null;

        return (
            <div className="container pt-5">
                {Task &&
                    <form onSubmit={this.submit}>
                        <Task key={this.state.key} task={this.getTask()} onTaskAnswerUpdate={this.onTaskAnswerUpdate} submitted={this.state.submitted}/>
                        <div className="row justify-content-center pt-5">
                            <div className="col-auto">
                                { !this.state.submitted && <button className="btn btn-primary" onClick={this.submit} disabled={this.state.answer === null}>Ответить</button> }
                                { this.state.submitted && <button className="btn btn-primary" onClick={this.newTask}>Дальше</button> }
                            </div>
                        </div>
                    </form>
                }
                <div className="row justify-content-center text-center mt-5">
                    <div className="col-12 mt-1">
                        <span className="badge rounded-pill bg-primary">Всего: { this.state.total }</span>
                    </div>
                    <div className="col-12 mt-1">
                        <span className="badge rounded-pill bg-success">Правильно: { this.state.correct }</span>
                    </div>
                    <div className="col-12 mt-1">
                        <a href="?admin" target="_blank" className="badge rounded-pill bg-danger">Неправильно: { this.state.wrong }</a>
                    </div>
                </div>
            </div>
        );
    }
}
