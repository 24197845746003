import {AbstractTask} from "../AbstractTask";
import Random from "../Random";
import {MultiplyTaskComponent} from "./TasksComponents/MultiplyTaskComponent";

export default class MultiplyTask extends AbstractTask {
    constructor() {
        super();

        this._first = Random(1, 9999);
        this._second = Random(1, 10);
    }

    getComponent() {
        return MultiplyTaskComponent;
    }

    checkAnswer(answer) {
        return {
            correct: +(answer === this.correctAnswer),
            wrong: +(answer !== this.correctAnswer),
            total: 1,
        };
    }

    get className() {
        return 'MultiplyTask';
    }

    get correctAnswer() {
        return this.first * this.second;
    }

    /**
     * @returns {Number}
     */
    get first() {
        return this._first;
    }

    /**
     * @returns {Number}
     */
    get second() {
        return this._second;
    }
}
