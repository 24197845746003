import {AbstractTask} from "../AbstractTask";
import Random from "../Random";
import {AdditionTaskComponent} from "./TasksComponents/AdditionTaskComponent";

export default class AdditionTask extends AbstractTask {
    constructor() {
        super();

        this._first = Random(1, 1000);
        this._second = Random(1, 1000);
    }

    getComponent() {
        return AdditionTaskComponent;
    }

    checkAnswer(answer) {
        return {
            correct: +(answer === this.correctAnswer),
            wrong: +(answer !== this.correctAnswer),
            total: 1,
        };
    }

    get className() {
        return 'AdditionTask';
    }

    get correctAnswer() {
        return this.first + this.second;
    }

    /**
     * @returns {Number}
     */
    get first() {
        return this._first;
    }

    /**
     * @returns {Number}
     */
    get second() {
        return this._second;
    }
}
