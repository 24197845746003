export class AbstractTask {

    /**
     * @param {*} answer
     *
     * @return {{total: Number, correct: Number, wrong: Number}}
     */
    checkAnswer(answer) {
        throw new Error('AbstractTask::isAnswerCorrect is abstract!');
    }

    /**
     * @return {string}
     */
    get className() {
        throw new Error('AbstractTask::isAnswerCorrect is abstract!');
    }

    /**
     * @return {*}
     */
    get correctAnswer() {
        throw new Error('AbstractTask::correctAnswer is abstract!');
    }

    /**
     * @return {*}
     */
    get getComponent() {
        throw new Error('AbstractTask::getComponent is abstract!');
    }
}
